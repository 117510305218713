<template>
  <div>
    <div class="card">
      <div class="card-body d-flex justify-content-center text-center align-item-center">
        <div ref="qrCode" style="width: 300px; height: 300px;">
          <h1 class="my-1"> {{ companyBranchName }}</h1>
          <qrcode-vue :value="link" size="150" />
          <h2 class="my-1">Kayıt Olmak İçin QRCode Okutunuz</h2>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center">
        <b-button size="md" variant="btn btn-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          @click="CreatePDFfromHTML()">
          {{ $t('Download PDF') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BButton, BCard, BCardText, BCol, BImg, BRow, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import QrcodeVue from "qrcode.vue"
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
// import { $themeColors } from '@themeConfig'
export default {
  name: 'index',
  components: {
    QrcodeVue,
    BCard,
    BButton,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API,
      companyBranchName: "",
      menuRoleGroupNameText: "",
      dataSrc: "",
    };
  },
  methods: {
    CreatePDFfromHTML() {
      var HTML_Width = 300;
      var HTML_Height = 300;
      var top_left_margin = 60;
      var PDF_Width = HTML_Width + (top_left_margin * 2);
      var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;
      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas(this.$refs.qrCode).then(function (canvas) {
        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
        for (var i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
        }
        pdf.save("QrCode.pdf");
      });
    }
  },
  created() {
    this.companyBranchName =
      this.$store.getters["auth/userInfo"].company_branch_name;
    this.menuRoleGroupNameText = this.siteURL
    this.$store.getters['auth/userInfo'].group_name +
      ' olarak katılma bağlantısı'
    // console.log("uygulama yolu " + `${window.location.host}`)

    this.link = `https://${window.location.host}/` + "customerInvite?param1=" + this.$store.getters['auth/userInfo'].company_branch_id

  },
};
</script>

<style scoped>

</style>
